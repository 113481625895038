const AppStoreIcon = () => {
    return (
        <svg
            width="18"
            height="23"
            viewBox="0 0 18 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.7973 12.3006C14.8081 11.4659 15.0298 10.6475 15.4418 9.92158C15.8538 9.19563 16.4428 8.58569 17.1539 8.14851C16.7021 7.50335 16.1062 6.97241 15.4134 6.59786C14.7205 6.22331 13.9499 6.01546 13.1627 5.99083C11.4835 5.81457 9.85552 6.99566 8.99981 6.99566C8.12754 6.99566 6.81004 6.00833 5.39131 6.03752C4.47364 6.06717 3.57929 6.33403 2.79541 6.81208C2.01154 7.29014 1.36486 7.9631 0.918393 8.76539C-1.01561 12.1138 0.426982 17.0349 2.27959 19.7415C3.20649 21.0668 4.28977 22.5473 5.70722 22.4948C7.09428 22.4372 7.61232 21.6103 9.28662 21.6103C10.9454 21.6103 11.4314 22.4948 12.8776 22.4614C14.366 22.4372 15.3038 21.1302 16.1981 19.7923C16.8641 18.8479 17.3766 17.8042 17.7166 16.6997C16.8518 16.334 16.1139 15.7218 15.5948 14.9395C15.0757 14.1572 14.7983 13.2394 14.7973 12.3006Z"
                fill="white"
            />
            <path
                d="M12.0656 4.21083C12.8772 3.23663 13.277 1.98445 13.1802 0.720215C11.9403 0.850435 10.7951 1.44299 9.97259 2.37982C9.57045 2.83749 9.26245 3.36993 9.0662 3.9467C8.86996 4.52347 8.78931 5.13326 8.82888 5.74121C9.44902 5.7476 10.0625 5.61319 10.6232 5.3481C11.1838 5.08301 11.6771 4.69416 12.0656 4.21083Z"
                fill="white"
            />
        </svg>
    );
};

export default AppStoreIcon;
