const InstagramIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2_431)">
        <path
          d="M9.87901 5.78318C7.18168 5.78318 5.00601 7.88728 5.00601 10.4959C5.00601 13.1045 7.18168 15.2086 9.87901 15.2086C12.5763 15.2086 14.752 13.1045 14.752 10.4959C14.752 7.88728 12.5763 5.78318 9.87901 5.78318ZM9.87901 13.5597C8.13592 13.5597 6.71092 12.1857 6.71092 10.4959C6.71092 8.80603 8.13168 7.43201 9.87901 7.43201C11.6263 7.43201 13.0471 8.80603 13.0471 10.4959C13.0471 12.1857 11.6221 13.5597 9.87901 13.5597V13.5597ZM16.0879 5.5904C16.0879 6.20154 15.579 6.68962 14.9513 6.68962C14.3194 6.68962 13.8147 6.19744 13.8147 5.5904C13.8147 4.98337 14.3236 4.49119 14.9513 4.49119C15.579 4.49119 16.0879 4.98337 16.0879 5.5904ZM19.3154 6.70603C19.2433 5.23357 18.8955 3.92927 17.7801 2.85466C16.669 1.78005 15.3203 1.44373 13.7978 1.3699C12.2286 1.28376 7.52521 1.28376 5.95601 1.3699C4.43771 1.43962 3.08905 1.77595 1.97365 2.85056C0.858245 3.92517 0.514718 5.22947 0.438379 6.70193C0.349316 8.21951 0.349316 12.7681 0.438379 14.2857C0.510477 15.7582 0.858245 17.0625 1.97365 18.1371C3.08905 19.2117 4.43347 19.548 5.95601 19.6218C7.52521 19.708 12.2286 19.708 13.7978 19.6218C15.3203 19.5521 16.669 19.2158 17.7801 18.1371C18.8913 17.0625 19.2391 15.7582 19.3154 14.2857C19.4045 12.7681 19.4045 8.22361 19.3154 6.70603V6.70603ZM17.2882 15.914C16.9574 16.7179 16.317 17.3373 15.4815 17.6613C14.2303 18.1412 11.2616 18.0304 9.87901 18.0304C8.49642 18.0304 5.52342 18.1371 4.27655 17.6613C3.4453 17.3414 2.8049 16.722 2.46985 15.914C1.97365 14.7041 2.08816 11.833 2.08816 10.4959C2.08816 9.15876 1.97789 6.28357 2.46985 5.07771C2.80066 4.2738 3.44106 3.65447 4.27655 3.33044C5.52767 2.85056 8.49642 2.9613 9.87901 2.9613C11.2616 2.9613 14.2346 2.85466 15.4815 3.33044C16.3127 3.65037 16.9531 4.2697 17.2882 5.07771C17.7844 6.28767 17.6699 9.15876 17.6699 10.4959C17.6699 11.833 17.7844 14.7082 17.2882 15.914Z"
          fill="url(#paint0_linear_2_431)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2_431"
          x1="0.371582"
          y1="4.76181"
          x2="19.4702"
          y2="-0.281922"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8806E2" />
          <stop offset="1" stop-color="#DD20F1" />
        </linearGradient>
        <clipPath id="clip0_2_431">
          <rect
            width="19"
            height="21"
            fill="white"
            transform="translate(0.375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InstagramIcon;
