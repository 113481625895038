const FacebookIcon = () => {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2_435)">
        <path
          d="M18.375 3.28125V17.7188C18.375 18.8057 17.4932 19.6875 16.4062 19.6875H12.9076V12.4195H15.3932L15.75 9.64687H12.9076V7.875C12.9076 7.07109 13.1291 6.52559 14.2816 6.52559H15.75V4.04824C15.4957 4.01543 14.6262 3.9375 13.609 3.9375C11.4926 3.9375 10.0406 5.22949 10.0406 7.6043V9.65098H7.54688V12.4236H10.0447V19.6875H1.96875C0.881836 19.6875 0 18.8057 0 17.7188V3.28125C0 2.19434 0.881836 1.3125 1.96875 1.3125H16.4062C17.4932 1.3125 18.375 2.19434 18.375 3.28125Z"
          fill="url(#paint0_linear_2_435)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2_435"
          x1="-8.42667e-08"
          y1="4.76786"
          x2="18.5388"
          y2="0.0340761"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8806E2" />
          <stop offset="1" stop-color="#DD20F1" />
        </linearGradient>
        <clipPath id="clip0_2_435">
          <rect width="18.375" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FacebookIcon;
