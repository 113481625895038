//styles
import style from "./styles.module.scss";

const FeaturesItem = ({ icon, title, description }) => {
  return (
    <div className={style.wrapper}>
      {icon}
      <h2 className={style.title}>{title}</h2>
      <p className={style.description}>{description}</p>
    </div>
  );
};

export default FeaturesItem;
