const GooglePlayIcon = () => {
    return (
        <svg
            width="25"
            height="26"
            viewBox="0 0 25 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.43518 0.538054C1.10727 0.930126 0.941696 1.43275 0.972384 1.94295V24.0591C0.941607 24.5693 1.10719 25.0719 1.43518 25.464L1.50918 25.5361L13.8982 13.1472V12.8549L1.50918 0.465454L1.43518 0.538054Z"
                fill="url(#paint0_linear_200_1502)"
            />
            <path
                d="M18.0276 17.2786L13.8982 13.1472V12.8549L18.0286 8.72449L18.1217 8.77759L23.0145 11.5577C24.4121 12.3517 24.4121 13.6509 23.0145 14.4454L18.1217 17.2255L18.0276 17.2786Z"
                fill="url(#paint1_linear_200_1502)"
            />
            <path
                d="M18.1216 17.225L13.8981 13.0011L1.43506 25.464C1.71983 25.7152 2.08348 25.8588 2.46302 25.87C2.84256 25.8812 3.21406 25.7593 3.51316 25.5254L18.1216 17.225Z"
                fill="url(#paint2_linear_200_1502)"
            />
            <path
                d="M18.1216 8.77706L3.51316 0.476657C3.21406 0.242751 2.84256 0.120844 2.46302 0.132058C2.08348 0.143272 1.71983 0.286899 1.43506 0.538057L13.8981 13.0011L18.1216 8.77706Z"
                fill="url(#paint3_linear_200_1502)"
            />
            <path
                opacity="0.2"
                d="M18.0285 17.132L3.51316 25.3793C3.22557 25.5982 2.87458 25.7176 2.51315 25.7196C2.15172 25.7215 1.79947 25.6058 1.50956 25.39L1.43506 25.4645L1.50906 25.5366L1.50956 25.5361C1.79942 25.752 2.15169 25.8678 2.51315 25.8659C2.87461 25.8639 3.22562 25.7444 3.51316 25.5254L18.1216 17.225L18.0285 17.132Z"
                fill="black"
            />
            <path
                opacity="0.12"
                d="M1.43516 25.3184C1.10728 24.9263 0.941709 24.4237 0.972361 23.9135V24.0596C0.941691 24.5698 1.10726 25.0724 1.43516 25.4645L1.50966 25.39L1.43516 25.3184Z"
                fill="black"
            />
            <path
                opacity="0.12"
                d="M23.0145 14.2988L18.0286 17.132L18.1217 17.225L23.0145 14.4449C23.3023 14.32 23.5513 14.1202 23.7357 13.8663C23.9201 13.6125 24.033 13.3138 24.0628 13.0015C24.0009 13.2843 23.8739 13.5488 23.692 13.774C23.51 13.9992 23.278 14.1789 23.0145 14.2988Z"
                fill="black"
            />
            <path
                opacity="0.25"
                d="M3.51331 0.623221L23.0145 11.7032C23.2782 11.8231 23.5102 12.0028 23.6922 12.2281C23.8742 12.4534 24.0011 12.7181 24.0628 13.001C24.0333 12.6886 23.9204 12.3898 23.736 12.1358C23.5516 11.8818 23.3025 11.682 23.0145 11.5571L3.51331 0.477121C2.11571 -0.316979 0.972412 0.342621 0.972412 1.94342V2.08952C0.972412 0.488821 2.11571 -0.170779 3.51331 0.623221Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_200_1502"
                    x1="12.7995"
                    y1="1.70976"
                    x2="-3.98289"
                    y2="18.4922"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#00A0FF" />
                    <stop offset="0.00657" stop-color="#00A1FF" />
                    <stop offset="0.2601" stop-color="#00BEFF" />
                    <stop offset="0.5122" stop-color="#00D2FF" />
                    <stop offset="0.7604" stop-color="#00DFFF" />
                    <stop offset="1" stop-color="#00E3FF" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_200_1502"
                    x1="24.8342"
                    y1="13.0015"
                    x2="0.637275"
                    y2="13.0015"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FFE000" />
                    <stop offset="0.4087" stop-color="#FFBD00" />
                    <stop offset="0.7754" stop-color="#FFA500" />
                    <stop offset="1" stop-color="#FF9C00" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_200_1502"
                    x1="15.8266"
                    y1="15.2962"
                    x2="-6.93167"
                    y2="38.0545"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FF3A44" />
                    <stop offset="1" stop-color="#C31162" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_200_1502"
                    x1="-1.70301"
                    y1="-6.82376"
                    x2="8.45951"
                    y2="3.33876"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#32A071" />
                    <stop offset="0.0685" stop-color="#2DA771" />
                    <stop offset="0.4762" stop-color="#15CF74" />
                    <stop offset="0.8009" stop-color="#06E775" />
                    <stop offset="1" stop-color="#00F076" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default GooglePlayIcon;
