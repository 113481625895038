import React, { useState, useRef } from "react";

//assets
import { ICONS, IMAGES } from "./assets";

//components
import GoogleButton from "./components/GoogleButton";
import AppleButton from "./components/AppleButton";
import FeaturesItem from "./components/FeaturesItem";
import PriceItem from "./components/PriceItem";
import WireframeItem from "./components/WireframeItem";

function App() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const featuresRef = useRef(null);
  const helpRef = useRef(null);

  const scrollToFeatures = () =>
    featuresRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const scrollToHelp = () =>
    helpRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

  const onChangeName = (event) => {
    setName(event.target.value);
  };

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const featuresItems = [
    {
      icon: ICONS.RobustIcon,
      title: "Order & Pickup",
      description:
        "Avoid crowded bar area by ordering and paying on the phone. Pick up when ready.",
    },
    {
      icon: ICONS.FlexibilityIcon,
      title: "Rewards",
      description:
        "Earn rewards at bars & nightclubs by simply checking in and ordering",
    },
    {
      icon: ICONS.UserFriendlyIcons,
      title: "Gifting",
      description:
        "Send a drink to anyone from anywhere. From home or at the venue",
    },
    {
      icon: ICONS.MultiplyLayoutIcon,
      title: "Sharing",
      description:
        "Let everyone know what’s popping by checking in and sharing stories",
    },
    {
      icon: ICONS.BetterComponentsIcon,
      title: "Recommendations",
      description:
        "Receive recommendations based on preferences and order history",
    },
    {
      icon: ICONS.WellOrganizedIcon,
      title: "Communicate",
      description:
        "Report an issue if it arises and resolve it directly with the venue",
    },
  ];

  const priceItems = [
    {
      price: "$0 Setup Cost",
      description: "Setup Cost",
    },
    {
      price: "0% Commissions",
      description: "Create your project prototype in just a few minutes",
    },
  ];

  const wireframeLeftItems = [
    {
      icon: ICONS.WireframeIcon,
      title: "Ithos",
      description:
        "Wingtipz allows to connect active sports coaches and students who want to adopt the experience of professionals in these disciplines.",
    },
    {
      icon: ICONS.WireframeIcon,
      title: "Ithos",
      description:
        "The best professionals: our interactive map shows the best professionals in your location, students have the opportunity to read profiles of schools or individual instructors and see reviews before booking a lesson.",
    },

    {
      icon: ICONS.WireframeIcon,
      title: "Ithos",
      description:
        "Convenient schedule: trainers set their own individual class schedule and students can book the time that suits them directly in the app.",
    },
  ];

  const wireframeRightItems = [
    {
      icon: ICONS.WireframeIcon,
      title: "Ithos",
      description:
        "Convenient payment: book classes in minutes with your bank card, we conduct transactions in U.S. dollars.",
    },
    {
      icon: ICONS.WireframeIcon,
      title: "Ithos",
      description:
        "Stay in touch with your coach: stay in touch with your coach after booking a class, the convenient in-app chat will help you do so.",
    },

    {
      icon: ICONS.WireframeIcon,
      title: "Ithos",
      description: "We operate in USD",
    },
  ];

  return (
    <div className="myWrapper">
      <header className="section-outer section-header">
        <div className="section-wrapper">
          <nav className="main-navigation">
            <div className="main-navigation-wrapper">
              <div className="logo-wrapper">
                <img src={IMAGES.Logo} className="logo" />
                <p className="logo-title"></p>
              </div>
              <ul className="nav-wrapper">
                <li className="nav-wrapper__item">
                  <div
                    onClick={scrollToFeatures}
                    href="#"
                    className="nav-wrapper__item-link"
                  >
                    Features
                  </div>
                </li>
                <li className="nav-wrapper__item">
                  <a href="#" className="nav-wrapper__item-link">
                    Pricing
                  </a>
                </li>
                <li className="nav-wrapper__item">
                  <a href="#" className="nav-wrapper__item-link">
                    Careers
                  </a>
                </li>
                <li className="nav-wrapper__item">
                  <div
                    onClick={scrollToHelp}
                    href="#"
                    className="nav-wrapper__item-link"
                  >
                    Help
                  </div>
                </li>
              </ul>
            </div>
            <div className="nav-btnWrapper">
              <GoogleButton />
              <AppleButton />
            </div>
          </nav>
          <div className="header-content">
            <div className="header-content__description">
              <h1 className="header-content__description-title">ZÔ</h1>
              <p className="header-content__description-subtitle">
                The Drinks Ordering App at Bars & Nightclubs. Something exciting
                is coming to nightlife. Forget standing in line and waiting
                forever to get your drinks. Get ZÔ and order off your phone. Get
                notified once it’s ready to pickup and go straight back to
                having a great time.
                <br />
                Get ZÔ today!
                <br />
              </p>
            </div>
            <div className="header-content__preview">
              <img
                src={IMAGES.HeaderPhone}
                alt="phone"
                className="header-content__preview-img"
              />
            </div>
          </div>
        </div>
      </header>
      <section ref={featuresRef} className="section-outer section-features">
        <div className="section-wrapper">
          <div className="features-content">
            <h1 className="features-content__title">
              The Drinks Ordering App at Bars & Nightclubs
            </h1>
            <p className="features-content__subtitle">
              Order at your convenience and so much more
            </p>
            <div className="features-content__items">
              {featuresItems.map((item, index) => (
                <FeaturesItem
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                  key={index}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="section-outer section-hyperframe">
        <div className="section-wrapper">
          <div className="hyperframe-wrapper">
            <div className="hyperframe-content">
              <h1 className="hyperframe-content__title">Launching in Miami</h1>
              <p className="hyperframe-content__subtitle">
                We’re launching in Miami, FL where nightlife is arguably the
                best in the world. Pay by credit card, ApplePay, PayPal with
                good old USD.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section-outer section-price">
        <div className="section-wrapper">
          <div className="price-content">
            <div className="price-content__items-wrapper">
              {priceItems.map((item, index) => (
                <PriceItem
                  price={item.price}
                  description={item.description}
                  key={index}
                />
              ))}
            </div>
          </div>
          <div className="wireframe-content">
            <div className="wireframe-content__left">
              <div className="myBlock">
                <img src={IMAGES.Group} alt="phone" className="myImage" />
                <div className="myTitle">Data</div>
                <div className="myDescription">
                  Collect customer data to understand customer demographic and
                  preferences to customize the user experience with specialized
                  items and offers.
                </div>
              </div>
              <div className="myBlock">
                <img src={IMAGES.Group} alt="phone" className="myImage" />
                <div className="myTitle">Promoting</div>
                <div className="myDescription">
                  Announce special occasions and events to attract attention and
                  interest.
                </div>
              </div>
              <div className="myBlock">
                <img src={IMAGES.Group} alt="phone" className="myImage" />
                <div className="myTitle">Feedback</div>
                <div className="myDescription">
                  Collect valuable feedback from your customers, who can add
                  star ratings and notes about their experience.
                </div>
              </div>
            </div>
            <div className="wireframe-content__right">
              <img
                src={IMAGES.PricePhone}
                alt="phone"
                className="wireframe-content__center-img"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <section className="section-outer section-info">
                <div className="section-wrapper">
                    <div className="info-content"></div>
                </div>
            </section> */}
      <section className="section-outer section-map">
        <div className="section-wrapper section-map-wrapper">
          <div className="map-modal">
            <p className="map-modal__title">Contacts</p>
            <ul className="map-modal-list">
              <li className="map-modal-list__item">
                Villa Medici, Yorba Linda, CA 92886, USA
              </li>
              <li className="map-modal-list__item">+1 917 2508715</li>
              <li className="map-modal-list__item">help@orderwithzo.com</li>
            </ul>
            <div className="map-modal-social-wrapper">
              <a className="map-modal-social-wrapper__link">
                {ICONS.FacebookIcon}
              </a>
              <a className="map-modal-social-wrapper__link">
                {ICONS.InstagramIcon}
              </a>
              <a className="map-modal-social-wrapper__link">
                {ICONS.TwitterIcon}
              </a>
            </div>
          </div>
        </div>
      </section>
      <section ref={helpRef} className="section-outer section-help">
        <div className="section-wrapper">
          <div className="help-content-wrapper">
            <div className="help-content">
              <p className="help-content__title">Need help?</p>
              <p className="help-content__subtitle">
                Please fill out this quick form and we'll get back to you within
                few hours!
              </p>
              <div className="help-content__form">
                <input
                  value={name}
                  onChange={onChangeName}
                  placeholder="Name"
                />
                <input
                  value={email}
                  onChange={onChangeEmail}
                  placeholder="Email"
                />
                <div className="help-content__form-button">
                  <span className="help-content__form-button__title">SEND</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="section-outer section-footer">
        <div className="section-wrapper">
          <div className="logo-wrapper logo-footer">
            <img src={IMAGES.Logo} className="logo" />
            {/* <p className="logo-title">
              <span className="logo-titleBold">Ithos</span>
            </p> */}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
