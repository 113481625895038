const WellOrganizedIcon = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.2081 0.175992L0.781403 6.91599C0.683842 6.96073 0.601194 7.03256 0.543297 7.12294C0.4854 7.21331 0.454694 7.31842 0.454834 7.42575C0.454975 7.53308 0.485955 7.63811 0.544088 7.72833C0.602222 7.81856 0.685058 7.89017 0.782736 7.93466L15.3227 14.6253C15.7521 14.8227 16.2481 14.8227 16.6774 14.6253L31.2174 7.93466C31.3151 7.8903 31.3981 7.81879 31.4563 7.72865C31.5146 7.6385 31.5457 7.53351 31.546 7.42618C31.5463 7.31885 31.5157 7.2137 31.4579 7.12325C31.4001 7.0328 31.3176 6.96086 31.2201 6.91599L16.7921 0.175992C16.5441 0.0601174 16.2738 6.10352e-05 16.0001 6.10352e-05C15.7264 6.10352e-05 15.456 0.0601174 15.2081 0.175992Z"
                fill="#7C3AED"
            />
            <path
                opacity="0.64"
                d="M31.22 15.568L28.24 14.176C28.0266 14.0763 27.794 14.0243 27.5584 14.0236C27.3229 14.0229 27.09 14.0735 26.876 14.172L16.6773 18.8653C16.4649 18.963 16.2338 19.0136 16 19.0136C15.7662 19.0136 15.5351 18.963 15.3227 18.8653L5.12267 14.172C4.90885 14.0737 4.67621 14.0232 4.4409 14.0239C4.20559 14.0246 3.97324 14.0765 3.76001 14.176L0.780006 15.568C0.682503 15.6128 0.599949 15.6848 0.54217 15.7752C0.48439 15.8657 0.453823 15.9708 0.454103 16.0782C0.454384 16.1855 0.485502 16.2905 0.543754 16.3806C0.602006 16.4708 0.684937 16.5423 0.782674 16.5866L15.3227 23.276C15.535 23.3742 15.7661 23.4251 16 23.4251C16.2339 23.4251 16.465 23.3742 16.6773 23.276L31.2173 16.5866C31.3151 16.5423 31.398 16.4708 31.4563 16.3806C31.5145 16.2905 31.5456 16.1855 31.5459 16.0782C31.5462 15.9708 31.5156 15.8657 31.4578 15.7752C31.4001 15.6848 31.3175 15.6128 31.22 15.568Z"
                fill="#C4B5FD"
            />
            <path
                d="M31.22 24.144L28.24 22.752C28.0266 22.6522 27.794 22.6002 27.5585 22.5995C27.3229 22.5989 27.09 22.6495 26.876 22.748L16.6774 27.44C16.4649 27.5376 16.2338 27.5882 16 27.5882C15.7662 27.5882 15.5351 27.5376 15.3227 27.44L5.12269 22.7466C4.90887 22.6484 4.67623 22.5978 4.44091 22.5985C4.2056 22.5992 3.97326 22.6511 3.76002 22.7506L0.780019 24.144C0.682891 24.189 0.600708 24.2609 0.543205 24.3512C0.485701 24.4415 0.455288 24.5464 0.455568 24.6535C0.455849 24.7605 0.486813 24.8653 0.544789 24.9553C0.602765 25.0453 0.685324 25.1168 0.782686 25.1613L15.3227 31.852C15.752 32.0493 16.248 32.0493 16.6774 31.852L31.2174 25.1613C31.3147 25.1168 31.3973 25.0453 31.4553 24.9553C31.5132 24.8653 31.5442 24.7605 31.5445 24.6535C31.5448 24.5464 31.5143 24.4415 31.4568 24.3512C31.3993 24.2609 31.3172 24.189 31.22 24.144Z"
                fill="#1DB5BE"
            />
        </svg>
    );
};

export default WellOrganizedIcon;
