//assets
import { ICONS } from "../../assets";

//styles
import style from "./styles.module.scss";

const PriceItem = ({ price, description }) => {
    return (
        <div className={style.wrapper}>
            <p className={style.title}>{price}</p>

            <p className={style.subtitle}>{description}</p>
        </div>
    );
};

export default PriceItem;
