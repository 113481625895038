const MultiplyLayoutIcon = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M28 32H16C15.6463 32 15.3072 31.8595 15.0571 31.6095C14.8071 31.3594 14.6666 31.0203 14.6666 30.6667V28C14.6666 27.6464 14.8071 27.3073 15.0571 27.0572C15.3072 26.8072 15.6463 26.6667 16 26.6667H28C28.3536 26.6667 28.6927 26.8072 28.9428 27.0572C29.1928 27.3073 29.3333 27.6464 29.3333 28V30.6667C29.3333 31.0203 29.1928 31.3594 28.9428 31.6095C28.6927 31.8595 28.3536 32 28 32Z"
                fill="#C4B5FD"
            />
            <path
                d="M28 0H3.99996C3.64634 0 3.3072 0.140475 3.05715 0.390523C2.8071 0.640572 2.66663 0.979711 2.66663 1.33333V4C2.66663 4.35362 2.8071 4.69276 3.05715 4.94281C3.3072 5.19286 3.64634 5.33333 3.99996 5.33333H28C28.3536 5.33333 28.6927 5.19286 28.9428 4.94281C29.1928 4.69276 29.3333 4.35362 29.3333 4V1.33333C29.3333 0.979711 29.1928 0.640572 28.9428 0.390523C28.6927 0.140475 28.3536 0 28 0Z"
                fill="#1DB5BE"
            />
            <path
                d="M28 8H3.99996C3.64634 8 3.3072 8.14047 3.05715 8.39052C2.8071 8.64057 2.66663 8.97971 2.66663 9.33333V22.6667C2.66663 23.0203 2.8071 23.3594 3.05715 23.6095C3.3072 23.8595 3.64634 24 3.99996 24H28C28.3536 24 28.6927 23.8595 28.9428 23.6095C29.1928 23.3594 29.3333 23.0203 29.3333 22.6667V9.33333C29.3333 8.97971 29.1928 8.64057 28.9428 8.39052C28.6927 8.14047 28.3536 8 28 8Z"
                fill="#7C3AED"
            />
        </svg>
    );
};

export default MultiplyLayoutIcon;
