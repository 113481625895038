// icons
import AppStoreIcon from "./icons/AppStoreIcon";
import ArrowDownIcon from "./icons/ArrowDownIcon";
import BetterComponentsIcon from "./icons/BetterComponentsIcons";
import CloseIcon from "./icons/CloseIcon";
import FacebookIcon from "./icons/FacebookIcon";
import FlexibilityIcon from "./icons/FlexibilityIcon";
import GooglePlayIcon from "./icons/GooglePlayIcon";
import InstagramIcon from "./icons/InstagramIcon";
import MultiplyLayoutIcon from "./icons/MultiplyLayoutIcon";
import RobustIcon from "./icons/RobustIcon";
import TwitterIcon from "./icons/TwitterIcon";
import UserFriendlyIcons from "./icons/UserFriendlyIcons";
import WellOrganizedIcon from "./icons/WellOrganizedIcon";
import WireframeIcon from "./icons/WireframeIcon";

//images
import HeaderBackground from "./images/HeaderBackground.png";
import Logo from "./images/Logo.png";
import HeaderPhone from "./images/HeaderPhone.png";
import HyperframeBackground from "./images/HyperframeBackground.png";
import PricePhone from "./images/PricePhone.png";
import Group from "./images/Group.png";

export const ICONS = {
  AppStoreIcon: <AppStoreIcon />,
  ArrowDownIcon: <ArrowDownIcon />,
  BetterComponentsIcon: <BetterComponentsIcon />,
  CloseIcon: <CloseIcon />,
  FacebookIcon: <FacebookIcon />,
  FlexibilityIcon: <FlexibilityIcon />,
  GooglePlayIcon: <GooglePlayIcon />,
  InstagramIcon: <InstagramIcon />,
  MultiplyLayoutIcon: <MultiplyLayoutIcon />,
  RobustIcon: <RobustIcon />,
  TwitterIcon: <TwitterIcon />,
  UserFriendlyIcons: <UserFriendlyIcons />,
  WellOrganizedIcon: <WellOrganizedIcon />,
  WireframeIcon: <WireframeIcon />,
};

export const IMAGES = {
  HeaderBackground,
  Logo,
  HeaderPhone,
  HyperframeBackground,
  PricePhone,
  Group,
};
