const TwitterIcon = () => {
  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2_433)">
        <path
          d="M20.1175 6.22278C20.1314 6.40932 20.1314 6.5959 20.1314 6.78244C20.1314 12.4721 15.5947 19.028 7.30276 19.028C4.74818 19.028 2.37511 18.3217 0.378906 17.0959C0.741863 17.1358 1.09081 17.1492 1.46773 17.1492C3.57557 17.1492 5.51595 16.4696 7.06545 15.3104C5.08321 15.2704 3.42204 14.0312 2.8497 12.3256C3.12891 12.3655 3.40807 12.3922 3.70125 12.3922C4.10606 12.3922 4.51091 12.3389 4.88779 12.2456C2.82181 11.8459 1.27227 10.1136 1.27227 8.02164V7.96836C1.8725 8.28816 2.57053 8.48803 3.31032 8.51465C2.09585 7.74179 1.3002 6.42265 1.3002 4.93026C1.3002 4.13078 1.52351 3.39791 1.91439 2.75831C4.13395 5.36998 7.47025 7.07554 11.2113 7.26212C11.1415 6.94232 11.0997 6.60923 11.0997 6.2761C11.0997 3.90425 13.1098 1.97217 15.6085 1.97217C16.9068 1.97217 18.0793 2.49184 18.903 3.3313C19.922 3.14476 20.8991 2.78497 21.7646 2.29197C21.4296 3.29135 20.7176 4.13082 19.7824 4.66378C20.6898 4.57055 21.5692 4.33065 22.3788 3.99756C21.7647 4.85032 20.9969 5.6098 20.1175 6.22278Z"
          fill="url(#paint0_linear_2_433)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2_433"
          x1="0.378906"
          y1="5.17946"
          x2="21.7083"
          y2="-1.84567"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8806E2" />
          <stop offset="1" stop-color="#DD20F1" />
        </linearGradient>
        <clipPath id="clip0_2_433">
          <rect
            width="22"
            height="21"
            fill="white"
            transform="translate(0.375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TwitterIcon;
