const WireframeIcon = () => {
    return (
        <svg
            width="51"
            height="50"
            viewBox="0 0 51 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.9998 31.4944H38.235L30.9204 18.5057L25.8002 26.2989L20.68 21.4745L12.9998 31.4944Z"
                fill="#163932"
            />
            <ellipse
                cx="20.5886"
                cy="14.7018"
                rx="1.7372"
                ry="1.76275"
                fill="#163932"
            />
            <circle
                cx="25.6177"
                cy="25"
                r="24"
                stroke="#163932"
                stroke-width="2"
            />
        </svg>
    );
};

export default WireframeIcon;
