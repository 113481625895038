const ArrowDownIcon = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="16" cy="16" r="16" fill="#163932" />
            <path
                d="M15.2234 21.1939C14.7818 20.8084 14.7818 20.1835 15.2234 19.7981L23.3679 12.689C23.8095 12.3036 24.5254 12.3036 24.967 12.689C25.4086 13.0745 25.4086 13.6994 24.967 14.0849L16.8225 21.1939C16.3809 21.5793 15.665 21.5793 15.2234 21.1939Z"
                fill="white"
            />
            <rect
                width="12.9333"
                height="2.12262"
                rx="1.06131"
                transform="matrix(0.753374 0.657592 -0.753374 0.657592 7.83203 11.9912)"
                fill="white"
            />
        </svg>
    );
};

export default ArrowDownIcon;
