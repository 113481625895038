//assets
import { ICONS } from "../../assets";

//styles
import style from "./styles.module.scss";

const AppleButton = () => {
  return (
    <a href="" className={style.wrapper}>
      {ICONS.AppStoreIcon}
      <div className={style.textWrapper}>
        <p className={style.subtitle}>Download on the</p>
        <p className={style.title}>App Store</p>
      </div>
    </a>
  );
};

export default AppleButton;
