const UserFriendlyIcon = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M30.6666 7.99998V2.66665C30.6666 2.31302 30.5261 1.97388 30.2761 1.72384C30.026 1.47379 29.6869 1.33331 29.3333 1.33331H2.66659C2.31296 1.33331 1.97382 1.47379 1.72378 1.72384C1.47373 1.97388 1.33325 2.31302 1.33325 2.66665V7.99998H30.6666Z"
                fill="#1DB5BE"
            />
            <path
                d="M1.33325 10.6666V29.3333C1.33325 29.6869 1.47373 30.0261 1.72378 30.2761C1.97382 30.5262 2.31296 30.6666 2.66659 30.6666H9.33325V10.6666H1.33325Z"
                fill="#C4B5FD"
            />
            <path
                d="M12 30.6666H29.3333C29.687 30.6666 30.0261 30.5262 30.2761 30.2761C30.5262 30.0261 30.6667 29.6869 30.6667 29.3333V10.6666H12V30.6666Z"
                fill="#7C3AED"
            />
        </svg>
    );
};

export default UserFriendlyIcon;
