//assets
import { ICONS } from "../../assets";

//styles
import style from "./styles.module.scss";

const GoogleButton = () => {
  return (
    <a href="" className={style.wrapper}>
      {ICONS.GooglePlayIcon}
      <div className={style.textWrapper}>
        <p className={style.subtitle}>Get in on</p>
        <p className={style.title}>Google Play</p>
      </div>
    </a>
  );
};

export default GoogleButton;
