const Flexibility = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.33325 1.33331H30.6666L26.6666 9.33331H1.33325L5.33325 1.33331Z"
                fill="#7C3AED"
            />
            <path
                d="M5.33325 12H30.6666L26.6666 20H1.33325L5.33325 12Z"
                fill="#1DB5BE"
            />
            <path
                d="M5.33325 22.6666H30.6666L26.6666 30.6666H1.33325L5.33325 22.6666Z"
                fill="#C4B5FD"
            />
        </svg>
    );
};

export default Flexibility;
