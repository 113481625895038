const CloseIcon = () => {
    return (
        <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="23"
                cy="22.6274"
                r="16"
                transform="rotate(45 23 22.6274)"
                fill="#163932"
            />
            <rect
                x="17.343"
                y="15.3545"
                width="18.2857"
                height="2.28571"
                rx="1.14286"
                transform="rotate(45 17.343 15.3545)"
                fill="white"
            />
            <rect
                x="30.2727"
                y="16.9703"
                width="18.2857"
                height="2.28571"
                rx="1.14286"
                transform="rotate(135 30.2727 16.9703)"
                fill="white"
            />
        </svg>
    );
};

export default CloseIcon;
